import React from "react";

export default function Nested() {
  return (
    <>
      <div>
        <h1>
          Nest Page
        </h1>
        <p>
          This is a page with a nested path.
        </p>
      </div>
    </>
  )
}