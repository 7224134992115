import React from "react";

export default function Home() {
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  return (
    <>
      <div>
        <h1>
          Home
        </h1>
        <p>
          <h2>
            Hello {name}
          </h2>
        </p>
        <p>
          A simple homepage
        </p>
      </div>
    </>
  )
}