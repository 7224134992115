import React from "react";

export default function Products() {
  return (
    <>
      <div>
        <h1>
          Products
        </h1>
        <p>
          <ul>
            <li>one</li>
            <li>two</li>
            <li>three</li>
          </ul>
        </p>
      </div>
    </>
  )
}