// Components/NavBar.js
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';

export function NavBar() {
  const location = useLocation();
  const menuItems = [
    {
      'label': 'Home',
      'path': '/'
    },
    {
      'label': 'About',
      'path': '/about'
    },
    {
      'label': 'Products',
      'path': '/products'
    },
    {
      'label': 'Nest',
      'path': '/nested/path'
    },
  ]

  const renderedButtons = menuItems.map(
    (item) => {
      const severity = location.pathname === item.path ? 'secondary' : 'primary';
      return (
        <div class="flex align-items-center justify-content-center font-bold text-white border-round mt-2 ml-2">
          <Link to={item.path}>
            <Button key={item.label} severity={severity} label={item.label} />
          </Link>
        </div>
      )
    }
  )

  return (
    <div class="card">
      <div class="flex flex-row flex-wrap card-container blue-container">
        {renderedButtons}
      </div>
    </div>
  )
}
