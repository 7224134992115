import React from "react";

export default function About() {
  return (
    <>
      <div>
        <h1>
          About Me
        </h1>
        <p>
          <ul>
            <li>lorem</li>
            <li>ipsum</li>
            <li>bla...</li>
          </ul>
        </p>
      </div>
    </>
  )
}