import { Route, Routes } from 'react-router-dom';
import './App.css';
import { NavBar } from './Components/Navbar';
import Products from './Pages/Products';
import About from './Pages/About';
import Home from './Pages/Home';
import Nested from './Pages/Nested';

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/products' element={<Products />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/nested/path' element={<Nested />}></Route>
      </Routes>
    </>
  );
}

export default App;
